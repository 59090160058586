import { useState } from "react";
import BigNumber from "bignumber.js";
import { contractAbi, contractAddress, usdtAbi, usdtContract } from "../../../constants";

export const PlatformFee = ({moralisObj}) => {
  const { user, Moralis } = moralisObj;
  const [pending, setPending] = useState(false);

  async function approveAndPayFee() {
    const bigAmount = new BigNumber(100).multipliedBy(new BigNumber(10).pow(18))
    const approveFn = {
      functionName: "approve",
      contractAddress: usdtContract,
      abi: usdtAbi,
      params: {
        spender: contractAddress,
        amount: bigAmount.toFixed()
      }
    }
    const payPlatformFeeFn = {
      contractAddress,
      functionName: "subscribe",
      abi: contractAbi,
      params: {
        userAddress: user.attributes.ethAddress,
      },
    };
    setPending(true)
    try {
      const res = await Moralis.executeFunction(approveFn)
      const result = await res.wait()
      if (result.status === 1) {
        const res = await Moralis.executeFunction(payPlatformFeeFn)
        const paymentResult = await res.wait()
        if (paymentResult.status === 1) {
          window.location.reload()
        } else {
          throw new Error('Payment Failed')
        }
      } else {
        throw new Error('Payment Failed')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setPending(false)
    }
  }

  return (
    <div className="platform-fee">
      <p>To purchase a package, a platform fee of <b>$100</b> is required, covering a
      period of 25 months.</p>
      {pending ? (
        <button
          className="sbtn sbtn-primary-disabled"
          disabled
          style={{ backgroundColor: "rgba(255,255,255,128)", color: "#a1a1a1" }}
        >
          Accept the transactions and wait...
        </button>
      ) : (
        <button
          className="sbtn sbtn-primary"
          onClick={() => approveAndPayFee()}
        >
          Activate
        </button>
      )}
    </div>
  );
}